import { throttle } from 'throttle-debounce';
import { mapGetters, mapMutations } from 'vuex';
import dateTimeManager from '@/common/date.time.manager';
import { UPDATE_PROFILE, UPDATE_USER_STATS } from '@/store/users/types';
import { CRON_EXECUTED } from '@/store/cron/types';
import cronsService from '@/services/crons.service';

const DELAY = 1000;

export default {
  computed: {
    ...mapGetters(['currentUser'])
  },
  created() {
    this.startCron();
  },
  beforeDestroy() {
    this.stopCron();
  },
  methods: {
    ...mapMutations({ updateProfile: UPDATE_PROFILE }),
    notifyUserForActivatedMissions(missions) {
      for (const mission of missions) {
        this.$notify({
          type: 'success',
          text: this.$i18n.t('missions.activatedMission', {
            name: mission.name
          })
        });
      }
    },
    notifyUserForFailedMissions(missions) {
      for (const mission of missions) {
        this.$notify({
          type: 'error',
          text: this.$i18n.t('missions.failedMission', {
            name: mission.name
          })
        });
      }
    },
    notifyForFailedUserStats(userStats) {
      const entries = Object.entries(userStats.diff);

      for (const [key, value] of entries) {
        if (value) {
          this.$notify({
            type: 'error',
            text: this.$i18n.t(`users.stats.failure.tasksAndMissions.${key}`, {
              value
            })
          });
        }
      }
    },
    notifyForLostRubyInGroups(groupMemberStats) {
      for (const groupStats of groupMemberStats) {
        this.$notify({
          type: 'error',
          text: this.$i18n.t('members.stats.failure.ruby', {
            groupName: groupStats.group.name,
            ruby: groupStats.memberStats.diff.ruby
          })
        });
      }
    },
    startCron() {
      this.checkNextCron();

      document.addEventListener('mousemove', this.checkNextCron);
      document.addEventListener('touchstart', this.checkNextCron);
      document.addEventListener('mousedown', this.checkNextCron);
      document.addEventListener('keydown', this.checkNextCron);
    },
    stopCron() {
      document.removeEventListener('mousemove', this.checkNextCron);
      document.removeEventListener('touchstart', this.checkNextCron);
      document.removeEventListener('mousedown', this.checkNextCron);
      document.removeEventListener('keydown', this.checkNextCron);
    },
    checkNextCron: throttle(DELAY, async function checkNextCron() {
      const currentDate = dateTimeManager.toICODateString(new Date());

      if (this.currentUser.holidayMode) return;

      if (this.currentUser && !this.currentUser.lastDayStart) return;

      if (this.currentUser.lastDayStart === currentDate) return;

      await this.runDailyCron();
    }),
    async runDailyCron() {
      const currentDate = dateTimeManager.toICODateString(new Date());

      const { result } = await cronsService.runDailyCron();

      if (result.missions.activatedMissions.length > 0) {
        this.notifyUserForActivatedMissions(result.missions.activatedMissions);
      }

      if (result.missions.failedMissions.length > 0) {
        this.notifyUserForFailedMissions(result.missions.failedMissions);
      }

      if (result.userStats && result.userStats.stats && result.userStats.diff) {
        this.$store.dispatch(UPDATE_USER_STATS, result.userStats.stats);
        this.notifyForFailedUserStats(result.userStats);
      }

      if (result.groupMemberStats.length > 0) {
        this.notifyForLostRubyInGroups(result.groupMemberStats);
      }

      this.$notify({
        type: 'success',
        text: this.$i18n.t('common.startNewDay')
      });

      this.updateProfile({ lastDayStart: currentDate });
      await this.$store.dispatch(CRON_EXECUTED, result);
    }
  }
};
