<template>
  <v-card
    class="cursor"
    v-if="group.id"
    @click="updateGroup"
    tile
    :ripple="false"
  >
    <v-container fluid>
      <v-row>
        <v-col md="4" sm="4" cols="12">
          <v-img
            v-if="group.imageUrl"
            contain
            height="120"
            :src="group.imageUrl"
          ></v-img>
          <v-img v-else height="120" :src="noImageAvailbale"></v-img>
        </v-col>
        <v-col md="8" sm="8" cols="12" class="pt-0">
          <v-card-title class="pb-0 no-flex-wrap">
            <h3 class="text-h6 one-line">{{ group.name }}</h3>
            <v-spacer></v-spacer>
            <v-menu v-if="canManageGroup" offset-y>
              <template v-slot:activator="{ on }">
                <v-btn icon class="app-dark-gray--text mr-0 my-0" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="openUpdateModal">
                  <v-list-item-title class="app-dark-gray--text">
                    <v-icon left class="mr-1">mdi-pencil</v-icon>
                    {{ $t('common.edit') }}
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="deleteGroup">
                  <v-list-item-title class="app-dark-gray--text">
                    <v-icon left class="mr-1">mdi-delete</v-icon>
                    {{ $t('common.delete') }}
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-title>
          <v-card-text class="text-subtitle-1 pt-0 pb-1">
            <span class="font-weight-light two-lines">
              {{ group.description }}
            </span>
            <v-chip dark label small color="app-green">
              <span class="text-subtitle-1 font-weight-medium">
                {{ curentMemberRole }}
              </span>
            </v-chip>
          </v-card-text>
          <v-card-actions class="px-4">
            <v-chip dark color="app-blue">
              <v-icon small class="mr-1">mdi-account-multiple</v-icon>
              <span class="text-subtitle-1 font-weight-bold">
                {{ group.membersCount }}
              </span>
            </v-chip>
            <v-chip color="app-yellow" text-color="white" class="pl-2 ml-2">
              <ruby-label
                :ruby="currentMemberRuby"
                textColor="white---text"
              ></ruby-label>
            </v-chip>
            <v-spacer></v-spacer>
            <div class="inline-block" v-if="!isDetailPage">
              <v-btn
                dark
                small
                color="app-blue"
                v-if="$vuetify.breakpoint.mdAndUp"
                @click.stop="viewDetails"
              >
                {{ $t('common.dashboard') }}
              </v-btn>
              <v-btn
                v-if="$vuetify.breakpoint.smAndDown"
                dark
                fab
                small
                depressed
                @click.stop="viewDetails"
                color="app-blue"
                width="35"
                height="35"
              >
                <v-icon>mdi-view-dashboard</v-icon>
              </v-btn>
            </div>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import ManageGroupModal from './ManageGroupModal';
import GroupInfoModal from './GroupInfoModal';
import ConfirmModal from '../shared/ConfirmModal';
import RubyLabel from '../shared/RubyLabel';
import groupRoles from '../../enums/group.roles';

const MAX_GROUPS_COUNT = 2;

export default {
  name: 'group-card',
  components: {
    RubyLabel
  },
  props: {
    isDetailPage: {
      type: Boolean,
      default: false
    },
    group: Object,
    del: Function
  },
  computed: {
    ...mapGetters(['currentUser', 'groups']),
    canManageGroup() {
      return this.group.currentMember.groupRole === groupRoles.creator;
    },
    leftGroupsCount() {
      let count = 0;
      for (const group of this.groups) {
        if (group.currentMember.groupRole === groupRoles.creator) {
          count++;
        }
      }

      return MAX_GROUPS_COUNT - count;
    },
    noImageAvailbale() {
      return require('../../assets/images/no-image.png');
    },
    currentMemberRuby() {
      return this.group.currentMember.stats.ruby;
    },
    curentMemberRole() {
      const { currentMember } = this.group;

      if (this.$i18n.te(`members.roles.${currentMember.displayRole}`)) {
        return this.$i18n.t(`members.roles.${currentMember.displayRole}`);
      }

      return currentMember.displayRole;
    }
  },
  methods: {
    viewDetails() {
      this.$router.push({ path: `/groups/${this.group.id}/dashboard` });
    },
    openUpdateModal() {
      if (!this.canManageGroup) {
        this.$root.$modal.show(GroupInfoModal, {
          group: { ...this.group }
        });
      } else {
        this.$root.$modal.show(ManageGroupModal, {
          selectedGroup: { ...this.group },
          isDetailPage: this.isDetailPage,
          leftGroupsCount: this.leftGroupsCount
        });
      }
    },
    updateGroup() {
      if (this.isDetailPage) {
        this.openUpdateModal();
      } else {
        this.viewDetails();
      }
    },
    async deleteGroup() {
      const confirmed = await this.$root.$modal.show(ConfirmModal, {
        text: this.$i18n.t('groups.deleteGroupConfirm')
      });

      if (!confirmed) return;

      this.del(this.group.id);
    }
  }
};
</script>
