<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-regular">
        {{ $t('groups.info') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container class="px-4">
      <v-row>
        <v-col md="4" sm="4" cols="12" class="pa-3">
          <v-img
            v-if="group.imageUrl"
            contain
            height="120"
            :src="group.imageUrl"
          ></v-img>
          <v-img v-else height="120" :src="noImageAvailbale"></v-img>
        </v-col>
        <v-col md="8" sm="8" cols="12">
          <v-card-title class="pb-0 no-flex-wrap pt-2">
            <h3 class="text-h6 pb-2">{{ group.name }}</h3>
          </v-card-title>
          <v-card-text class="text-subtitle-1 pt-0 pb-1">
            <span class="font-weight-light two-lines">
              {{ group.description }}
            </span>
          </v-card-text>
          <v-card-actions>
            <v-chip dark color="app-green">
              <v-icon class="mr-2">mdi-account-multiple</v-icon>
              <span class="text-subtitle-1 font-weight-bold">
                {{ group.membersCount }}
              </span>
            </v-chip>
          </v-card-actions>
        </v-col>
      </v-row>
    </v-container>
  </v-card>
</template>

<script>
export default {
  name: 'group-info-modal',
  props: {
    group: Object
  },
  data() {
    return {
      noImageAvailbale: require('../../assets/images/no-image.png')
    };
  }
};
</script>
