function transformToGroupUpdateReqBody(group) {
  return {
    id: group.id,
    name: group.name,
    description: group.description,
    imageUrl: group.imageUrl,
    leaderRoleDisplayName: group.leaderRoleDisplayName,
    memberRoleDisplayName: group.memberRoleDisplayName
  };
}

export default {
  transformToGroupUpdateReqBody
};
