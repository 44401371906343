<template>
  <fragment>
    <v-icon class="mr-2 height-20 ruby-icon">$vuetify.icons.ruby</v-icon>
    <span
      class="text-subtitle-1 font-weight-medium text-no-wrap"
      :class="textColor"
    >
      {{ ruby | numberFormat }}
    </span>
  </fragment>
</template>

<script>
export default {
  name: 'ruby-label',
  props: {
    ruby: {
      required: true
    },
    textColor: {
      type: String,
      default: 'app-green--text'
    }
  }
};
</script>
