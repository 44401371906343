<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light">
        {{ title }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-6 pb-0">
      <div v-if="toggleNoteText">
        <p class="text-center font-weight-medium">
          <strong>{{ $t('common.note') }}: </strong>
          <span>{{ $t('groups.cannotCreateMoreThan2Groups') }}</span>
        </p>

        <p class="text-center app-red--text">
          <strong v-if="leftGroupsCount > 0">
            {{ $t('groups.youCanCreateGroups', { count: leftGroupsCount }) }}
          </strong>
          <strong v-else>
            {{ $t('groups.cannotCreateMoreGroups') }}
          </strong>
        </p>
      </div>

      <v-form ref="manageGroupForm" v-if="canAddGroup || canEditGroup">
        <v-text-field
          solo
          v-model="group.name"
          :rules="[rules.required]"
          :label="$t('common.name')"
        ></v-text-field>
        <v-textarea
          solo
          v-model="group.description"
          :rules="[rules.required]"
          :label="$t('common.description')"
        ></v-textarea>
        <v-text-field
          solo
          v-model="group.imageUrl"
          :rules="[rules.isUrl]"
          :label="$t('common.imageUrl')"
        ></v-text-field>
        <v-text-field
          solo
          v-model="group.leaderRoleDisplayName"
          :label="$t('groups.leaderRoleDisplayName')"
          :hint="$t('groups.defaultLeaderRoleDisplayName')"
        ></v-text-field>
        <v-text-field
          solo
          v-model="group.memberRoleDisplayName"
          :label="$t('groups.memberRoleDisplayName')"
          :hint="$t('groups.defaultMemberRoleDisplayName')"
        ></v-text-field>
      </v-form>
    </v-container>
    <v-card-actions v-if="canAddGroup || canEditGroup">
      <v-spacer></v-spacer>
      <v-btn color="app-blue" text @click="save" :loading="loading">
        {{ $t('common.save') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import {
  ADD_GROUP,
  UPDATE_GROUP,
  UPDATE_CURRENT_GROUP
} from '../../store/groups/types';
import groupsMapper from '../../mappers/groups.mapper';
import validators from '../../common/validators';
import userTypes from '../../enums/user.types';

export default {
  name: 'manage-group-modal',
  props: {
    selectedGroup: Object,
    isDetailPage: {
      type: Boolean,
      default: false
    },
    leftGroupsCount: {
      type: Number,
      default: 0
    }
  },
  computed: {
    ...mapGetters(['currentUser']),
    title() {
      if (this.group.id) {
        return this.$i18n.t('groups.editGroup');
      }

      return this.$i18n.t('groups.createGroup');
    },
    rules() {
      return {
        required: validators.required(this.$i18n.t('common.requiredField')),
        isUrl: validators.isUrl(this.$i18n.t('common.invalidUrl'))
      };
    },
    toggleNoteText() {
      return this.isBasicUser && !this.selectedGroup;
    },
    canAddGroup() {
      return (
        !this.isBasicUser || (this.leftGroupsCount > 0 && !this.selectedGroup)
      );
    },
    canEditGroup() {
      return this.selectedGroup;
    },
    isBasicUser() {
      return this.currentUser.type === userTypes.basic;
    }
  },
  data() {
    return {
      loading: false,
      group: {
        name: '',
        description: '',
        imageUrl: '',
        leaderRoleDisplayName: '',
        memberRoleDisplayName: ''
      }
    };
  },
  created() {
    if (this.selectedGroup) {
      this.group = this.selectedGroup;
    }
  },
  methods: {
    async create() {
      await this.$store.dispatch(ADD_GROUP, this.group);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('groups.groupCreatedSuccessfully')
      });
    },
    async update() {
      const payload = groupsMapper.transformToGroupUpdateReqBody(this.group);

      if (this.isDetailPage) {
        await this.$store.dispatch(UPDATE_CURRENT_GROUP, payload);
      } else {
        await this.$store.dispatch(UPDATE_GROUP, payload);
      }

      this.$notify({
        type: 'success',
        text: this.$i18n.t('groups.groupUpdatedSuccessfully')
      });
    },
    async save() {
      if (!this.$refs.manageGroupForm.validate()) {
        return;
      }

      try {
        this.loading = true;

        if (this.group.id) {
          await this.update();
        } else {
          await this.create();
        }
      } finally {
        this.loading = false;
        this.$emit('close', this.group);
      }
    }
  }
};
</script>
