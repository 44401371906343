<template>
  <v-card tile>
    <v-card-title class="text-h5">{{ text }}</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="app-blue" text @click="confirm(false)">
        {{ $t('common.no') }}
      </v-btn>
      <v-btn color="app-blue" text @click="confirm(true)">
        {{ $t('common.yes') }}
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: 'confirm-modal',
  props: {
    text: String
  },
  methods: {
    confirm(value) {
      this.$emit('close', value);
    }
  }
};
</script>
