<template>
  <v-card tile>
    <v-toolbar flat color="app-blue" dark class="card-header">
      <span class="text-h5 font-weight-light">
        {{ $t('groups.myGroupsStats') }}
      </span>
      <v-spacer></v-spacer>
      <v-btn text icon @click="$emit('close')" class="mr-0">
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-toolbar>
    <v-container grid-list-md class="pa-md-6 px-sm-3">
      <v-card-text>
        <v-card
          class="mb-5"
          v-for="groupStats of groupsStats"
          :key="groupStats.id"
          tile
        >
          <v-row align="center" justify="center" class="pl-2">
            <v-col cols="12" sm="9">
              <v-chip class="ml-0" small label color="app-green" dark>
                <span class="text-body-2 font-weight-medium">
                  {{ groupStats.name }}
                </span>
              </v-chip>
            </v-col>
            <v-col cols="12" sm="3">
              <v-chip color="app-yellow" text-color="white" class="pl-1">
                <ruby-label
                  :ruby="groupStats.memberStats.ruby"
                  textColor="white---text"
                ></ruby-label>
              </v-chip>
            </v-col>
          </v-row>
        </v-card>
      </v-card-text>
    </v-container>
  </v-card>
</template>

<script>
import RubyLabel from '../shared/RubyLabel';

export default {
  name: 'current-user-groups-stats',
  components: {
    RubyLabel
  },
  props: {
    groupsStats: Array
  }
};
</script>
