<template>
  <div>
    <v-row v-if="groups.length > 0">
      <v-col md="6" sm="12" cols="12" v-for="group of groups" :key="group.id">
        <group-card :group="group" :del="del" class="mb-0"></group-card>
      </v-col>
    </v-row>
    <div v-else class="text-center py-4">
      <h1 class="heading app-dark-gray--text font-weight-light">
        {{ $t('groups.noGroupsFound') }}
      </h1>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupCard from './GroupCard';
import { DELETE_GROUP } from '../../store/groups/types';

export default {
  name: 'groups',
  components: {
    GroupCard
  },
  computed: {
    ...mapGetters(['groups'])
  },
  methods: {
    async del(payload) {
      await this.$store.dispatch(DELETE_GROUP, payload);

      this.$notify({
        type: 'success',
        text: this.$i18n.t('groups.groupDeletedSuccessfully')
      });
    }
  }
};
</script>
