<template>
  <v-container grid-list-md fluid class="pa-4">
    <v-row v-if="$vuetify.breakpoint.smAndDown" class="hidden-md-and-up mb-3">
      <v-col cols="12">
        <user-profile-card></user-profile-card>
        <div class="text-center mt-3 mb-2">
          <support-us></support-us>
        </div>
        <div class="text-center">
          <feedback></feedback>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-card class="border-top-blue mb-3" tile>
          <v-card-title
            class="text-h5 app-dark-gray--text font-weight-light py-2"
          >
            <v-icon left>mdi-account-multiple</v-icon>
            <span>{{ $t('groups.myGroups') }}</span>
            <v-spacer></v-spacer>
            <v-btn
              v-if="currentUser.groupsCount > 0"
              dark
              fab
              small
              depressed
              @click="displayMyGroupsStats()"
              color="app-blue"
              class="mr-3"
            >
              <v-icon>mdi-poll</v-icon>
            </v-btn>
            <v-btn dark fab small depressed @click="open()" color="app-blue">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
        <groups></groups>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import UserProfileCard from '@/components/users/UserProfileCard';
import SupportUs from '@/components/shared/SupportUs';
import Feedback from '@/components/shared/Feedback';
import Groups from '@/components/groups/Groups';
import ManageGroupModal from '@/components/groups/ManageGroupModal';
import CurrentUserGroupsStatsModal from '@/components/groups/CurrentUserGroupsStatsModal';
import {
  GET_GROUPS,
  GET_CURRENT_USER_GROUPS_STATS
} from '@/store/groups/types';
import groupRoles from '../../enums/group.roles';
import dailyCronMixin from '../../mixins/daily.cron.mixin';

const MAX_GROUPS_COUNT = 2;

export default {
  name: 'groups-view',
  components: {
    UserProfileCard,
    SupportUs,
    Feedback,
    Groups
  },
  mixins: [dailyCronMixin],
  computed: {
    ...mapGetters(['currentUser', 'groups']),
    leftGroupsCount() {
      let count = 0;
      for (const group of this.groups) {
        if (group.currentMember.groupRole === groupRoles.creator) {
          count++;
        }
      }

      return MAX_GROUPS_COUNT - count;
    }
  },
  data() {
    return {
      dailyCronWatcher: null
    };
  },
  async created() {
    this.watchDailyCron();
    await this.$store.dispatch(GET_GROUPS);
  },
  beforeDestroy() {
    this.dailyCronWatcher();
  },
  methods: {
    open() {
      this.$root.$modal.show(ManageGroupModal, {
        leftGroupsCount: this.leftGroupsCount
      });
    },
    async displayMyGroupsStats() {
      const result = await this.$store.dispatch(GET_CURRENT_USER_GROUPS_STATS);

      this.$root.$modal.show(CurrentUserGroupsStatsModal, {
        groupsStats: result
      });
    },
    watchDailyCron() {
      this.dailyCronWatcher = this.$store.watch(
        (state, getters) => getters.cronData,
        cronData => {
          if (!cronData) return;

          this.$store.dispatch(GET_GROUPS);
        }
      );
    }
  }
};
</script>
